import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "optimization-flex.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                    <div>
                    <div className="main-text">
                    Flexible <span>optimization.</span>
                    </div>
                    <div className="sub-text">
                    Incorporate all vehicle and city restrictions. 
                    </div>
                    </div>
                    <div className='child'>
                        <p> The route that the vehicle can drive to collect the waste depends on various among others the time 
                          windows of the customer, the type of vehicle, the restrictions in the city or the preferences of the driver. </p>
                    </div>
                    <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                    <div className='child'>
                    <h3> For every problem. </h3>
                    <p>Our optimiation algorithms help you find the best ways of collecting the waste streams. No matter how complex your precesses,
                      you have tens or thousands of locations to visit - our algorithm ensures you always have the optimal route.</p>
                    </div>
                    <div className='child right'>
                    <h3> Incorporate all restrictions. </h3>
                    <p> To minimize traffic flows in cities, municipalities can impose restrictions on certain vehicle types. 
                      For example certain areas can only be accesed by electric vehicles, other areas should be avoided in general between certain time slots. 
                      We maintain a detailed graph, or map, of the city which incorporates all city restrictions and driver preferences and uses the GPS driving time estimates
                      obtained from DispatchX for a data-driven and flexible optimization.
                       </p>
                    </div>
                    <div className='child'>
                    <h3> The classics and the state-of-the-art. </h3>
                    <p>Our algorithms are based on a combination of classical mixed-integer linear programming solvers and machine learning 
                      algorithms for finding the optimal schedules and routes.  </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero

